import React, { useEffect, useState } from 'react';
import ResponsiveModal from 'react-responsive-modal';
import styles from './styles.module.scss';

const Modal = ({
  render,
  title,
  isOpen,
  onOpen,
  onClose,
  children,
  disableFocusTrap,
  expanded,
  id,
  customHeader,
  className,
  ...rest
}) => {
  useEffect(() => {
    if (isOpen) {
      if (onOpen) {

        onOpen();
      }
    }
  }, [isOpen])
  return (
    <ResponsiveModal
      open={isOpen}
      onClose={() => onClose()}
      center
      showCloseIcon={false}
      classNames={{
        modal: expanded
          ? styles.expandedModal
          : className
            ? styles[className]
            : styles.modal,
        overlay: styles.overlay,
      }}
      onActivate={() => console.log('activated')}
    >
      <div
        className="modal-content overflow-hidden"
        id={`modal${id ? `_${id}` : ``}`}
      >
        <div className="modal-header pb-0 border-0">
          {customHeader ? (
            customHeader()
          ) : (
            <h5 className="modal-title">{title}</h5>
          )}
          <button
            tabIndex={disableFocusTrap ? '0' : '-1'}
            className="btn close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onClose()}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </ResponsiveModal>
  );
};

Modal.defaultProps = {
  isOpen: false,
  title: 'modal',
  expanded: false,
  onClose: () => { },
  customHeader: false,
};

export default Modal;
