import React from 'react';

const trackEvent = (code, extraArgs) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: code,
      ...extraArgs,
    });
    if (window.fbq) {
      window.fbq('track', code, extraArgs);
    }
  } catch (err) {
    console.log('err', err)
  }

};
const EventTracker = ({ children }) => {
  return <>{children({ trackEvent })}</>;
};

export default EventTracker;
