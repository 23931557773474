import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import Cookies from 'js-cookie';

const PromoCodeModal = ({ isOpen, onClose }) => {
    const promoCodeUserValue = Cookies.get('promo_code_user_value');
    const [promoCode, setPromoCode] = useState(promoCodeUserValue);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isPromoApplied, setIsPromoApplied] = useState(false);

    useEffect(() => {
        return () => {
            // Cleanup function to reset state when component unmounts
            setIsSubmitted(false);
            setIsPromoApplied(false);
        };
    }, []); // Empty dependency array means this effect runs only on mount and unmount

    const handleSubmit = (e) => {
        e.preventDefault();
        Cookies.set('promo_code_user_value', promoCode, { expires: 365 });
        const cleanedPromoCode = promoCode.toUpperCase().replace(/[^A-Z]/g, '');
        const isValidPromo = cleanedPromoCode.includes('HALFPRICENOW');

        if (isValidPromo) {
            // Set cookies
            Cookies.set('onlinebooking', 'true', { expires: 365 }); // Set cookie to expire in 1 year
            Cookies.set('promo', 'al_test_temp', { expires: 365 }); // Set cookie to expire in 1 year
            console.log('Promo code applied:', promoCode);
            console.log('Cookies set: onlinebooking=true, promo=al_test_temp');
            setIsPromoApplied(true);
        } else {
            console.log('Invalid promo code:', promoCode);
            setIsPromoApplied(true);
        }

        setIsSubmitted(true);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isSubmitted ? (isPromoApplied ? "Promo Code Applied" : "Invalid Promo Code") : "Enter Promo Code"}
        >
            {isSubmitted ? (
                <div>
                    <p>{isPromoApplied ? "Thank you. Your promo code has been applied." : "Sorry, the entered promo code is invalid."}</p>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <button onClick={onClose} className="btn btn-secondary text-primary font-weight-bold rounded">Close</button>
                    </div>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            id="promoCode"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            required
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <button type="submit" className="btn btn-secondary text-primary font-weight-bold rounded">Apply</button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default PromoCodeModal;